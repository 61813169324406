<template>
<section class=statistics-section ref=statsSection aria-label=statistics>
<div class=containerik>
<div class=stat>
<div class=number>{{ year }} &nbsp; |</div>
<div class=label>{{ $t('StatistikPage.title4') }}</div>
</div>
<div class=stat>
<div class=number>+{{ brand }} &nbsp;|</div>
<div class=label>{{ $t('StatistikPage.title') }}</div>
</div>
<div class=stat>
<div class=number>+{{ countries }} &nbsp;|</div>
<div class=label>{{ $t('StatistikPage.title2') }}</div>
</div>
<div class=stat>
<div class=number>+{{ clients }} &nbsp;</div>
<div class=label>{{ $t('StatistikPage.title3') }}</div>
</div>
</div>
</section>
</template>
<script>
import{ref as e,onMounted as t,onUnmounted as a,computed as l}from"vue";import{useI18n as r}from"vue-i18n";export default{name:"StatisticsSection",setup(){let{t:s}=r(),u=e(null),i=e(0),n=e(9e3),o=e(0),v=e(0),$=e({stats:!1}),c=e(window.innerWidth),d=l(()=>c.value<=390?"sm":c.value<=600?"hp":"default"),g=e=>{let t="assets/img/business-4/back/",a=d.value,l="low"===e?"_low":"";switch(a){case"sm":return`${t}skor_sm${l}.webp`;case"hp":return`${t}skor_hp${l}.webp`;default:return`${t}skor${l}.webp`}},m=()=>{u.value&&(u.value.style.backgroundSize="cover",u.value.style.backgroundPosition="center",u.value.style.width="100%",u.value.style.position="relative")},h=e=>new Promise((t,a)=>{let l=new Image;l.onload=()=>t(l),l.onerror=a,l.src=e}),p=()=>{if(u.value){m();let e=g("low");u.value.style.backgroundImage=`url(${e})`}},w=async()=>{try{let e=g("high");await h(e),u.value&&(u.value.style.backgroundImage=`url(${e})`,$.value.stats=!0)}catch(t){console.error("Error loading high quality image:",t)}},b=()=>{$.value.stats=!1,p(),w()},y=()=>{let e=window.innerWidth,t=d.value;c.value=e,t!==d.value&&b()},k=e=>e.toString().replace(/\B(?=(\d{3})+(?!\d))/g,"."),_=(e,t,a)=>{let l=e.value,r=t-l,s=t>l?1:-1,u=setInterval(()=>{l+=s,e.value=k(l),l===t&&clearInterval(u)},a/Math.abs(r))},f=()=>{_(i,40,.5),_(n,1e4,.5),_(o,50,.5),_(v,1e3,.5)};return t(()=>{p(),w(),window.addEventListener("resize",y),f()}),a(()=>{window.removeEventListener("resize",y)}),{t:s,statsSection:u,year:i,brand:n,countries:o,clients:v}}};
</script>
<style src="@/assets/css/about/StatisticsSection.css"></style>
