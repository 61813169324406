// src/mixins/metaMixin.js

// MetaHelpers object for utility functions
const MetaHelpers = {
  // Core meta tag manipulation functions
  setMetaTag(attributeName, name, content) {
    if (!content) return;
    try {
      let meta = document.querySelector(`meta[${attributeName}="${name}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute(attributeName, name);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', this.sanitizeContent(content));
    } catch (error) {
      console.error(`Error setting meta tag ${name}:`, error);
    }
  },

  getFullImageUrl(imagePath, baseUrl, defaultImage) {
    try {
      if (!imagePath) return `${baseUrl}${defaultImage}`;
      return imagePath.startsWith('http') ? imagePath : `${baseUrl}${imagePath}`;
    } catch (error) {
      console.error('Error getting full image URL:', error);
      return `${baseUrl}${defaultImage}`;
    }
  },

  setCanonicalUrl(url) {
    if (!url) return;
    try {
      const cleanUrl = url.replace(/\/+$/, '').split('?')[0];
      let canonical = document.querySelector('link[rel="canonical"]');
      if (!canonical) {
        canonical = document.createElement('link');
        canonical.setAttribute('rel', 'canonical');
        document.head.appendChild(canonical);
      }
      canonical.setAttribute('href', cleanUrl);
      this.setMetaTag('property', 'og:url', cleanUrl);
    } catch (error) {
      console.error('Error setting canonical URL:', error);
    }
  },

  // Content formatting and sanitization
  formatContent(content) {
    if (!content) return '';
    try {
      return content
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/&\w+;/g, ' ')  // Replace HTML entities with space
        .replace(/[^\w\s-]/g, '') // Remove special characters
        .replace(/\s+/g, ' ')    // Replace multiple spaces with single space
        .trim();
    } catch (error) {
      console.error('Error formatting content:', error);
      return '';
    }
  },

  sanitizeContent(content) {
    if (!content) return '';
    try {
      return content
        .replace(/["'<>]/g, '')  // Remove quotes and angle brackets
        .replace(/&/g, '&amp;')  // Escape ampersands
        .trim();
    } catch (error) {
      console.error('Error sanitizing content:', error);
      return '';
    }
  },

  createSlug(text, options = {}) {
    if (!text) return '';
    try {
      const {
        maxLength = 60,
        separator = '-'
      } = options;

      return this.formatContent(text)
        .toLowerCase()
        .replace(/\s+/g, separator)
        .substr(0, maxLength)
        .replace(new RegExp(`${separator}+$`), '');
    } catch (error) {
      console.error('Error creating slug:', error);
      return '';
    }
  },

  cleanupMetaTags() {
    try {
      // Remove duplicate meta tags
      const seen = new Set();
      document.querySelectorAll('meta').forEach(meta => {
        const name = meta.getAttribute('name') || meta.getAttribute('property');
        if (name) {
          if (seen.has(name)) {
            meta.remove();
          } else {
            seen.add(name);
          }
        }
      });

      // Remove empty meta tags
      document.querySelectorAll('meta').forEach(meta => {
        const content = meta.getAttribute('content');
        if (!content || content.trim() === '') {
          meta.remove();
        }
      });
    } catch (error) {
      console.error('Error cleaning up meta tags:', error);
    }
  }
};

// Main mixin export
export const metaMixin = {
  data() {
    return {
      baseUrl: 'http://192.168.98.220:8080',
      defaultImage: '/assets/img/business-4/slider/default-banner.webp',
      imageSize: {
        width: 1200,
        height: 630
      },
      socialLinks: {
        facebook: 'https://www.facebook.com/puracigarettesolutions',
        instagram: 'https://www.instagram.com/pura.mics/',
        youtube: 'https://www.youtube.com/channel/UC_1YqET6QtMRvh7IlD-TJYg',
        tiktok: 'https://www.tiktok.com/@puracigarette',
        linkedin: 'https://www.linkedin.com/company/puracigarette/'
      },
      nofollowPaths: [
        '/loginds', '/dashboard', '/DashboardDs',
        '/EditArtikel', '/EditDetailProduk', '/EditKategori',
        '/EditProduk', '/EditPromo', '/EditSosmed',
        '/EditUltah', '/RegisterDs', '/TambahArtikel',
        '/TambahDetailProduk', '/TambahKategori', '/TambahProduk',
        '/TambahSosmed', '/TampilanArtikel', '/TampilanEtiket',
        '/TampilanCp', '/TampilanCtp', '/TampilanCustomer',
        '/TampilanDetailProduk', '/TampilanFilter', '/TampilanKategori',
        '/TampilanPromo', '/TampilanQuestion', '/TampilanSosmed',
        '/TampilanUsers', '/UlangTahun', '/api',
        '/assets', '/js', '/css', '/routes', '/backend',
        '/404', '/error', '/not-found', '/maintenance',
        '/coming-soon', '/under-construction', '/temp',
        '/temporary'
      ],
      allowedPaths: [
        '/', '/AboutPage', '/BlogPura', '/ContactUs',
        '/CustomerPage', '/ProdukPage', '/SamplePage',
        '/SimulasiCp', '/SimulasiCtp', '/DetailBlog',
        '/DetailProduk'
      ],
      defaultDescription: 'Leading provider of innovative cigarette and tobacco solutions.',
      defaultKeywords: 'tobacco solutions, cigarette manufacturing, tobacco industry'
    };
  },

  methods: {
    getFullImageUrl(imagePath) {
      if (!imagePath) return `${this.baseUrl}${this.defaultImage}`;
      return imagePath.startsWith('http') ? imagePath : `${this.baseUrl}${imagePath}`;
    },

    formatPageTitle(title, siteName = 'Pura MICS') {
      try {
        return title ? `${title} | ${siteName}` : siteName;
      } catch (error) {
        console.error('Error formatting page title:', error);
        return siteName;
      }
    },

    shouldAllowRobots(path) {
      const isNofollow = this.nofollowPaths.some(nofollowPath => 
        path.startsWith(nofollowPath) ||
        path.includes('/edit/') ||
        path.includes('/add/') ||
        path.includes('/delete/') ||
        path.includes('/update/') ||
        path.includes('/manage/') ||
        path.includes('/admin/') ||
        path.includes('/dashboard/') ||
        path.includes('/login/') ||
        path.includes('/register/')
      );

      if (isNofollow) return false;

      return this.allowedPaths.some(allowedPath => 
        path === allowedPath || 
        (path.startsWith('/DetailBlog/') && path.includes('/')) ||
        (path.startsWith('/DetailProduk/') && path.includes('/'))
      );
    },

    updateMetaTags(metaInfo) {
      if (!metaInfo) return;
      
      const fullImageUrl = MetaHelpers.getFullImageUrl(metaInfo.image, this.baseUrl, this.defaultImage);
      const currentPath = this.$route?.path || '';
      
      // Basic meta tags
      MetaHelpers.setMetaTag('name', 'description', metaInfo.description);
      MetaHelpers.setMetaTag('name', 'keywords', metaInfo.keywords);
      MetaHelpers.setMetaTag('name', 'author', 'Pura MICS');

      // Robots directive
      const robotsDirective = this.getRobotsDirective(metaInfo);
      MetaHelpers.setMetaTag('name', 'robots', robotsDirective);
      MetaHelpers.setMetaTag('name', 'googlebot', robotsDirective);

      // Open Graph tags
      MetaHelpers.setMetaTag('property', 'og:title', metaInfo.title);
      MetaHelpers.setMetaTag('property', 'og:description', metaInfo.description);
      MetaHelpers.setMetaTag('property', 'og:image', fullImageUrl);
      MetaHelpers.setMetaTag('property', 'og:url', metaInfo.url || `${this.baseUrl}${currentPath}`);
      MetaHelpers.setMetaTag('property', 'og:type', metaInfo.type || 'website');
      MetaHelpers.setMetaTag('property', 'og:site_name', 'Pura MICS');

      // Twitter Card tags
      MetaHelpers.setMetaTag('name', 'twitter:card', 'summary_large_image');
      MetaHelpers.setMetaTag('name', 'twitter:title', metaInfo.title);
      MetaHelpers.setMetaTag('name', 'twitter:description', metaInfo.description);
      MetaHelpers.setMetaTag('name', 'twitter:image', fullImageUrl);

      // Update canonical URL
      if (metaInfo.canonical) {
        MetaHelpers.setCanonicalUrl(metaInfo.canonical);
      }

      // Update document title
      document.title = this.formatPageTitle(metaInfo.title);

      // Clean up meta tags
      MetaHelpers.cleanupMetaTags();
    },

    getRobotsDirective(metaInfo) {
      const currentPath = this.$route?.path || '';
      
      if (!this.shouldAllowRobots(currentPath)) {
        return 'noindex, nofollow';
      }

      switch (metaInfo.type) {
        case 'product':
          return 'index, nofollow, max-snippet:-1, max-image-preview:large';
        case 'article':
          return 'index, nofollow, max-snippet:-1, max-image-preview:large';
        default:
          return 'index, nofollow';
      }
    },

    getMetaConfig() {
      const configs = {
        home: {
          title: 'Pura Material Innovations and Cigarette Solutions (MICS) - Home',
          description: 'PURA GROUP produces various special products to support the tobacco industry, including Homogenized Tobacco Leaf Wrapper, Cigarette Tipping Paper, Colored Cigarette Paper, and Cigarette Packs.',
          keywords: [
            // English Keywords
            'pura mics', 'cigarette solutions', 'pura group', 'packaging material',
            'tobacco industry solutions', 'cigarette manufacturing',
            'homogenized tobacco leaf', 'tipping paper', 'cigarette components',
            // Indonesian Keywords
            'produk rokok', 'pabrik rokok', 'industri rokok indonesia',
            'kertas rokok', 'filter rokok', 'kemasan rokok',
            'bahan baku rokok', 'pembungkus rokok', 'produsen rokok',
            'kertas tipping rokok', 'mesin rokok', 'perlengkapan rokok',
            'solusi industri rokok', 'produsen kertas rokok',
            'kemasan rokok premium', 'supplier rokok', 'distributor rokok',
            'percetakan kemasan rokok', 'material pendukung rokok',
            'industri kretek', 'rokok kretek', 'kota kretek'
          ].join(', '),
          image: '/assets/img/business-4/slider/LANDINGPAGE.webp',
          imageAlt: 'Pura MICS Home Banner',
          type: 'website',
          url: this.baseUrl,
          canonical: this.baseUrl,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        about: {
          title: 'About Pura MICS - A GUIDING LIGHT IN THE TOBACCO INDUSTRY',
          description: 'Learn about Pura MICS, a leading innovator in cigarette solutions. Discover our history, values, and commitment to quality in the tobacco industry.',
          keywords: [
            // English Keywords
            'pura mics', 'pura group subsidiary', 'indonesian cigarette manufacturer',
            'tobacco manufacturing solutions', 'cigarette industry experience',
            'quality manufacturing standards', 'sustainable tobacco solutions',
            // Indonesian Keywords
            'perusahaan rokok indonesia', 'produsen rokok terkemuka',
            'inovasi industri rokok', 'standar kualitas rokok',
            'pengembangan produk rokok', 'solusi manufaktur rokok',
            'pelopor industri rokok', 'ahli pembuatan rokok',
            'teknologi pembuatan rokok', 'riset pengembangan rokok',
            'konsultan industri rokok', 'pengolahan tembakau modern'
          ].join(', '),
          image: '/assets/img/business-4/slider/about.webp',
          imageAlt: 'Pura MICS About Banner',
          type: 'website',
          url: `${this.baseUrl}/AboutPage`,
          canonical: `${this.baseUrl}/AboutPage`,
          robotsDirectives: 'index, follow, max-snippet:-1'
        },
        product: {
          title: 'Pura MICS Products - Innovative Cigarette Solutions',
          description: 'Explore our comprehensive range of cigarette solutions including Homogenized Tobacco Leaf Wrapper, Tipping Paper, and Cigarette Packs.',
          keywords: [
            // English Keywords
            'cigarette paper', 'tipping paper', 'cigarette pack',
            'filter tips', 'individual cigarette pack', 'pre-rolled cones',
            'ryo/myo', 'tobacco processing solutions', 'quality cigarette components',
            // Indonesian Keywords
            'kertas rokok premium', 'kertas tipping berkualitas',
            'kemasan rokok modern', 'filter rokok premium',
            'bahan baku rokok', 'produk rokok berkualitas',
            'pembungkus rokok premium', 'material rokok terbaik',
            'solusi kemasan rokok', 'produk pendukung rokok',
            'bahan kemasan rokok', 'aksesori rokok modern',
            'produk inovasi rokok', 'material rokok terkini'
          ].join(', '),
          image: '/assets/img/business-4/slider/PRODUCT.webp',
          imageAlt: 'Pura MICS Produk Banner',
          type: 'website',
          url: `${this.baseUrl}/ProdukPage`,
          canonical: `${this.baseUrl}/ProdukPage`,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        contactus: {
          title: 'Contact Pura MICS - Get in Touch With Us',
          description: 'Contact Pura MICS for innovative cigarette solutions. Reach out to our team for inquiries about our products and services.',
          keywords: [
            // English Keywords
            'pura mics contact', 'cigarette manufacturer contact',
            'tobacco solutions contact', 'manufacturing consultation',
            'business collaboration',
            // Indonesian Keywords
            'kontak produsen rokok', 'konsultasi produk rokok',
            'kerjasama industri rokok', 'layanan konsumen rokok',
            'konsultasi material rokok', 'supplier bahan rokok',
            'distributor produk rokok', 'mitra bisnis rokok'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/ContactUs`,
          canonical: `${this.baseUrl}/ContactUs`,
          robotsDirectives: 'index, follow, noarchive'
        },
        Sampel: {
          title: 'Request Samples - Pura MICS Products',
          description: 'Request samples of our premium cigarette solutions. Experience the quality of Pura MICS products firsthand.',
          keywords: [
            // English Keywords
            'cigarette paper samples', 'tipping paper samples',
            'filter samples', 'packaging samples', 'tobacco product samples',
            // Indonesian Keywords
            'sampel kertas rokok', 'sampel filter rokok',
            'sampel kemasan rokok', 'contoh produk rokok',
            'sampel material rokok', 'sampel bahan rokok',
            'sampel kertas tipping', 'contoh kemasan premium'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/SamplePage`,
          canonical: `${this.baseUrl}/SamplePage`,
          robotsDirectives: 'noindex, nofollow'
        },
        BlogPura: {
          title: 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights',
          description: 'Stay updated with the latest tobacco industry news, product innovations, manufacturing insights, and events from Pura MICS.',
          keywords: [
            // English Keywords
            'tobacco industry news', 'cigarette manufacturing updates',
            'tobacco product innovations', 'industry best practices',
            'manufacturing standards',
            // Indonesian Keywords
            'berita industri rokok', 'inovasi produk rokok',
            'perkembangan industri rokok', 'standar produksi rokok',
            'teknologi rokok terbaru', 'tren industri rokok',
            'update manufaktur rokok', 'artikel industri rokok'
          ].join(', '),
          image: '/assets/img/business-4/slider/produkk.webp',
          imageAlt: 'Pura MICS Blog Banner',
          type: 'website',
          url: `${this.baseUrl}/BlogPura`,
          canonical: `${this.baseUrl}/BlogPura`,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        simulasi: {
          title: 'CTP Simulation - Pura MICS Tools',
          description: 'Use our CTP simulation tool to visualize and customize your tobacco product solutions.',
          keywords: [
            // English Keywords
            'CTP simulation', 'tipping paper calculator', 'cigarette design tool',
            // Indonesian Keywords
            'simulasi rokok', 'kalkulator kertas tipping',
            'alat desain rokok', 'simulasi produk rokok',
            'perancangan rokok', 'desain kemasan rokok'
          ].join(', '),
          url: `${this.baseUrl}/simulasiCtp`,
          canonical: `${this.baseUrl}/simulasiCtp`,
          robotsDirectives: 'noindex, follow'
        }
      };

      const routeName = this.$route?.name?.toLowerCase() || 'home';
      return configs[routeName];
    },

    async updateMetaForDetailProduct() {
      try {
        const product = this.product || this.currentProduct;
        if (!product) return;

        const currentLanguage = this.$i18n?.locale || 'en';
        const title = MetaHelpers.formatContent(
          currentLanguage === 'en' ? product.judul_en : product.judul_id
        );
        const description = MetaHelpers.formatContent(
          currentLanguage === 'en' ? 
          (product.deskripsi_en || product.keterangan_en) : 
          (product.deskripsi_id || product.keterangan_id)
        );
        
        const metaInfo = {
          title: `Pura MICS Products - ${title}`,
          description: description || this.defaultDescription,
          keywords: `${product.keyword || ''}, ${this.defaultKeywords}`.trim(),
          image: product.gambar1 ? `${this.baseUrl}/api/produk/file/${product.gambar1}` : null,
          type: 'product'
        };

        this.updateMetaTags(metaInfo);
      } catch (error) {
        console.error('Error updating product meta:', error);
      }
    },

    async updateMetaForDetailBlog() {
      try {
        if (!this.article) return;

        const currentLanguage = this.$i18n?.locale || 'en';
        const title = MetaHelpers.formatContent(
          currentLanguage === 'en' ? this.article.judul_en : this.article.judul_id
        );
        const description = MetaHelpers.formatContent(
          currentLanguage === 'en' ? 
            this.article.deskripsi_en : 
            this.article.deskripsi_id
        );

        const metaInfo = {
          title: title,
          description: description || 'Read our latest article about tobacco industry innovations.',
          keywords: this.article.keyword || this.defaultKeywords,
          image: this.article.gambar1 ? `${this.baseUrl}/api/artikel/file/${this.article.gambar1}` : null,
          type: 'article'
        };

        this.updateMetaTags(metaInfo);
      } catch (error) {
        console.error('Error updating article meta:', error);
      }
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        const config = this.getMetaConfig();
        if (config) {
          this.updateMetaTags(config);
        }
      }
    },

    '$i18n.locale'() {
      const config = this.getMetaConfig();
      if (config) {
        this.updateMetaTags(config);
      } else if (this.$route?.name === 'DetailProduct') {
        this.updateMetaForDetailProduct();
      } else if (this.$route?.name === 'DetailBlog') {
        this.updateMetaForDetailBlog();
      }
    },

    product: {
      handler(newProduct) {
        if (newProduct && this.$route?.name === 'DetailProduct') {
          this.updateMetaForDetailProduct();
        }
      },
      deep: true
    },

    article: {
      handler(newArticle) {
        if (newArticle && this.$route?.name === 'DetailBlog') {
          this.updateMetaForDetailBlog();
        }
      },
      deep: true
    }
  }
};