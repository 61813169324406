<template>
<HeaderCom />
<section class=saasio-breadcurmb-section-contact>
<div class=container>
<div class=breadcurmb-title-contact>
<h1>{{ $t('ContactUs.title') }}</h1>
<p>{{ $t('ContactUs.subtitle') }}</p>
</div>
</div>
</section>
<ContactForm />
<ContactMaps />
<FooterCom />
</template>
<script>
import{defineComponent as o}from"vue";import{metaMixin as t}from"@/mixins/metaMixin";import m from"@/components/HeaderCom.vue";import n from"@/components/FooterCom.vue";import e from"@/components/contact/ContactForm.vue";import r from"@/components/contact/ContactMaps.vue";export default o({name:"ContactUs",components:{HeaderCom:m,FooterCom:n,ContactForm:e,ContactMaps:r},mixins:[t]});
</script>