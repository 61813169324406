<template>
<section id=error-404 class=apbc-error-section style=background-color:#111 data-aos=fade-up>
<div class=container>
<div class="row justify-content-center">
<div class="col-lg-8 text-center">
<div class=error-content>
<h1 class=error-title>404</h1>
<div class=error-description>
<h2>Page Not Found</h2>
<p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
</div>
<div class=helpful-links>
<h3>Helpful Links</h3>
<div class=link-grid>
<router-link to=/ class=nav-link-found>
Back to Home >
</router-link>
<router-link to=/ProdukPage class=nav-link-found>
View Products >
</router-link>
<router-link to=/BlogPura class=nav-link-found>
Read Our Blog >
</router-link>
<router-link to=/ContactUs class=nav-link-found>
Contact Us >
</router-link>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</template>
<script>
import{onMounted as o}from"vue";import{useRouter as t}from"vue-router";import e from"aos";import"aos/dist/aos.css";export default{name:"NotFound",setup(){let r=t();return o(()=>{e.init({offset:0,duration:800,easing:"ease-in-out",once:!0,startEvent:"load",delay:0,anchorPlacement:"top-bottom"})}),{router:r}}};
</script>
<style>
.apbc-error-section{min-height:100vh;display:flex;align-items:center;padding:80px 0;background-color:#111}.error-content{background:rgba(255,255,255,.05);padding:3rem;border-radius:10px;backdrop-filter:blur(10px)}.error-title{font-size:8rem;color:#d4af37;margin-bottom:2rem;font-weight:700}.error-description h2{color:#fff;font-size:2rem;margin-bottom:1rem}.error-description p{color:#ccc;font-size:1.1rem;margin-bottom:2rem}.helpful-links h3{color:#fff;font-size:1.5rem;margin-bottom:1.5rem}.link-grid{display:grid;grid-template-columns:repeat(2,1fr);gap:1rem;max-width:600px;margin:0 auto}.nav-link-found{display:inline-block;color:#d4af37;text-decoration:none;padding:.8rem 1.5rem;border:1px solid #d4af37;border-radius:5px;transition:.3s;font-size:1rem}.nav-link-found:hover{background-color:#d4af37;color:#111;transform:translateY(-2px)}@media (max-width:768px){.error-title{font-size:6rem}.error-description h2{font-size:1.5rem}.link-grid{grid-template-columns:1fr}.nav-link-found{width:100%;text-align:center}}
</style>