<template>
<a href=# class=apbc-scroll-top aria-label="Scroll to top">
<i class="fas fa-angle-double-up"></i>
</a>
<HeaderCom />
<section class=saasio-breadcurmb-section :class="{ 'loaded': isHighResLoaded }" ref=breadcrumbSection :style=backgroundStyle>
<div class=image-wrapper>
<img :src=currentImageSrc :srcset=imageSrcSet sizes="(max-width: 768px) 100vw, 100vw" class=background-image :class="{ 'loaded': isHighResLoaded }" @load=handleImageLoad @error=handleImageError alt="About section background" loading=eager>
</div>
<div class=container>
<div class=breadcurmb-title>
<h1><strong>{{ $t('aboutPage.title') }}</strong></h1>
<p class=text-base>
{{ $t('aboutPage.content') }}
<a href=https://www.puragroup.com/en/home/ class="text-blue-600 hover:text-blue-800" target=_blank rel="noopener noreferrer">
Pura Grup
</a>
{{ $t('aboutPage.content1') }}
</p>
<h4>{{ $t('aboutPage.subtitle') }}</h4>
<p>{{ $t('aboutPage.content2') }}</p>
<p>{{ $t('aboutPage.content3') }}</p>
<p>{{ $t('aboutPage.content4') }}</p>
<p>{{ $t('aboutPage.more') }}</p>
</div>
</div>
</section>
<StoryTimeline />
<CoreValues />
<QuotesSection />
<StatisticsSection />
<BannerSection />
<FacilitiesSection />
<Certificate />
<PatentSectin />
<FooterCom />
</template>
<script>
import{ref as e,onMounted as o,computed as t,onBeforeUnmount as i}from"vue";import{useI18n as n}from"vue-i18n";import{metaMixin as r}from"@/mixins/metaMixin";import m from"@/components/FooterCom.vue";import u from"@/components/HeaderCom.vue";import s from"@/components/PatentSection.vue";import a from"@/components/Certificate.vue";import p from"@/components/about/StoryTimelineSection.vue";import l from"@/components/about/CoreValuesSection.vue";import c from"@/components/about/QuotesSection.vue";import v from"@/components/about/StatisticsSection.vue";import f from"@/components/about/BannerSection.vue";import b from"@/components/about/FacilitiesSection.vue";export default{name:"AboutPage",mixins:[r],components:{HeaderCom:u,FooterCom:m,StoryTimeline:p,CoreValues:l,QuotesSection:c,StatisticsSection:v,BannerSection:f,FacilitiesSection:b,PatentSectin:s,Certificate:a},setup(){let{t:r}=n(),m=e(!1),u=e(!1),s=e(null),a=e(window.innerWidth),p=e(!1),l=t(()=>({low:(p.value,"assets/img/business-4/slider/about_low.webp"),high:(p.value,"assets/img/business-4/slider/about.webp")})),c=t(()=>l.value.high),v=t(()=>`${l.value.high} 1920w`),f=t(()=>({"--aspect-ratio":a.value<=768?"4/3":"16/9",backgroundColor:"#000000"})),b=()=>{let e=new Image;e.src=l.value.high,e.onload=()=>{m.value=!0},e.onerror=()=>{p.value=!0}},$=e=>{let o=e.target;o.src.includes("_low")&&(u.value=!0,b())},d=()=>{p.value=!0},h,g=()=>{h&&clearTimeout(h),h=setTimeout(()=>{a.value=window.innerWidth},250)};return o(()=>{g(),window.addEventListener("resize",g),b()}),i(()=>{window.removeEventListener("resize",g),h&&clearTimeout(h)}),{t:r,isHighResLoaded:m,isLowResLoaded:u,breadcrumbSection:s,currentImageSrc:c,imageSrcSet:v,backgroundStyle:f,handleImageLoad:$,handleImageError:d}}};
</script>