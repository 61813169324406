<template>
<div class=banner-section-about>
<div class=banner-content-about>
<div class=banner-text-content-about>
<h2>
{{ $t('derecAbout.title1') }}
<span class=top-notch-highlight>{{ $t('derecAbout.title2') }}</span>
{{ $t('derecAbout.title3') }}
</h2>
<p>{{ $t('derecAbout.desc') }}</p>
<button class=primary-button-about>
<router-link to=/SamplePage>
{{ $t('derecAbout.buttom') }}
<span class=arrow-icon>></span>
</router-link>
</button>
</div>
<div class=banner-image-wrapper-about ref=imageWrapper>
<img :src=currentBannerImage :srcset=imageSrcset alt="Banner Image" class=banner-image-about ref=bannerImage @load=handleImageLoad loading=eager fetchpriority=high>
</div>
</div>
</div>
</template>
<script>
import{ref as e,computed as s,onMounted as l,onUnmounted as t}from"vue";export default{name:"BannerSection",setup(){let a=e(null),n=e(null),i=e(!1),r=e("desktop"),u=(e="high")=>{let s=r.value,l="low"===e?"_low":"",t="/assets/img/business-4/slider/banner_aboutt";switch(s){case"small":return`${t}_small${l}.webp`;case"mobile":return`${t}_mobile${l}.webp`;default:return`${t}${l}.webp`}},b=s(()=>i.value?u("high"):u("low")),o=s(()=>{let e=i.value?"":"_low";return`
        /assets/img/business-4/slider/banner_aboutt_small${e}.webp 390w,
        /assets/img/business-4/slider/banner_aboutt_mobile${e}.webp 600w,
        /assets/img/business-4/slider/banner_aboutt${e}.webp 1200w
      `}),m=()=>{if(!i.value){let e=new Image;e.onload=()=>{i.value=!0},e.src=u("high")}},w,d=()=>{w&&clearTimeout(w),w=setTimeout(()=>{let e=window.innerWidth;e<=390?r.value="small":e<=600?r.value="mobile":r.value="desktop"},250)};return l(()=>{d(),window.addEventListener("resize",d)}),t(()=>{w&&clearTimeout(w),window.removeEventListener("resize",d)}),{bannerImage:a,imageWrapper:n,currentBannerImage:b,imageSrcset:o,handleImageLoad:m}}};
</script>
<style src="@/assets/css/about/BannerSection.css"></style>