import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

// Import Vue components for routes
import HomeCom from '../views/HomeCom.vue';
import LoginDs from '../dashboard/LoginDs.vue';
import RegisterDs from '../dashboard/RegisterDs.vue';
import DashboardDs from '../dashboard/DashboardDs.vue';
import EditArtikel from '../dashboard/EditArtikel.vue';
import EditProduk from '../dashboard/EditProduk.vue';
import EditDetailProduk from '../dashboard/EditDetailProduk.vue';
import EditKategori from '../dashboard/EditKategori.vue';
import DetailProduk from '../views/DetailProduk.vue';
import BlogPura from '@/views/BlogPura.vue';
import TampilanProduk from '@/dashboard/TampilanProduk.vue';
import TampilanDetailProduk from '@/dashboard/TampilanDetailProduk.vue';
import TampilanKategori from '@/dashboard/TampilanKategori.vue';
import TampilanArtikel from '@/dashboard/TampilanArtikel.vue';
import TambahProduk from '@/dashboard/TambahProduk.vue';
import TambahDetailProduk from '@/dashboard/TambahDetailProduk.vue';
import TambahKategori from '@/dashboard/TambahKategori.vue';
import DetailBlog from '@/views/DetailBlog.vue';
import TambahArtikel from '@/dashboard/TambahArtikel.vue';
import AboutPage from '@/views/AboutPage.vue';
import SamplePage from '@/views/SamplePage.vue';
import TampilanSosmed from '@/dashboard/TampilanSosmed.vue';
import TambahSosmed from '@/dashboard/TambahSosmed.vue';
import EditSosmed from '@/dashboard/EditSosmed.vue';
import ProdukPage from '@/views/ProdukPage.vue';
import TampilanUsers from '@/dashboard/TampilanUsers.vue';
import ContactUs from '@/views/ContactUs.vue';
import NotFound from '@/views/NotFound.vue';
import TampilanCtp from '@/dashboard/TampilanCtp.vue';
import TampilanQuestion from '@/dashboard/TampilanQuestion';

// Define your routes
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeCom,
    },
    {
        path: '/DetailBlog/:id_artikel',
        name: 'DetailBlog',
        component: DetailProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/BlogPura',
        name: 'blog',
        component: BlogPura,
    },
    {
        path: '/LoginDs',
        name: 'LoginDs',
        component: LoginDs,
    },
    {
        path: '/RegisterDs',
        name: 'RegisterDs',
        component: RegisterDs,
        meta: { requiresAuth: true }
    },
    {
        path: '/DashboardDs',
        name: 'DashboardDS',
        component: DashboardDs,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanArtikel',
        name: 'TampilanArtikel',
        component: TampilanArtikel,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanProduk',
        name: 'TampilanProduk',
        component: TampilanProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanDetailProduk',
        name: 'TampilanDetailProduk',
        component: TampilanDetailProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanKategori',
        name: 'TampilanKategori',
        component: TampilanKategori,
        meta: { requiresAuth: true }
    },
    {
        path: '/TambahKategori',
        name: 'TambahKategori',
        component: TambahKategori,
        meta: { requiresAuth: true }
    },
    {
        path: '/TambahProduk',
        name: 'TambahProduk',
        component: TambahProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/TambahDetailProduk',
        name: 'TambahDetailProduk',
        component: TambahDetailProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/TambahArtikel',
        name: 'TambahArtikel',
        component: TambahArtikel,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditProduk/:id_produk',
        name: 'EditProduk',
        component: EditProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditDetailProduk/:id_detail_produk',
        name: 'EditDetailProduk',
        component: EditDetailProduk,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditArtikel/:id_artikel',
        name: 'EditArtikel',
        component: EditArtikel,
    },
    {
        path: '/EditKategori/:id_kategori',
        name: 'EditKategori',
        component: EditKategori,
    },
    {
        path: '/DetailProduk/:slug',
        name: 'DetailProduk',
        component: DetailProduk,
    },
    {
        path: '/DetailBlog/:slug',
        name: 'DetailBlog',
        component: DetailBlog
    },
    {
        path: '/AboutPage',
        name: 'about',
        component: AboutPage,
    },
    {
        path: '/ContactUs',
        name: 'contactus',
        component: ContactUs,
    },
    {
        path: '/ProdukPage',
        name: 'product',
        component: ProdukPage,
    },
    {
        path: '/SamplePage',
        name: 'Sampel',
        component: SamplePage,
    },
    {
        path: '/TampilanSosmed',
        name: 'TampilanSosmed',
        component: TampilanSosmed,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanUsers',
        name: 'TampilanUsers',
        component: TampilanUsers,
        meta: { requiresAuth: true }
    },
    {
        path: '/TambahSosmed',
        name: 'TambahSosmed',
        component: TambahSosmed,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditSosmed/:id_sosmed',
        name: 'EditSosmed',
        component: EditSosmed,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanQuestion',
        name: 'TampilanQuestion',
        component: TampilanQuestion,
        meta: { requiresAuth: true }
    },
    {
        path: '/TampilanCtp',
        name: 'TampilanCtp',
        component: TampilanCtp,
        meta: { requiresAuth: true }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: { 
            title: 'Page Not Found',
            requiresAuth: false 
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: to => {
            // Simpan URL asli yang tidak ditemukan
            if (to.path !== '/404') {
                localStorage.setItem('notFoundUrl', to.fullPath);
            }
            return { name: 'NotFound' };
        }
    }
];

// Create router instance
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if the user is logged in from Vuex store
        if (!store.getters.isLoggedIn) {
            // If not logged in, redirect to the login page
            next({ name: 'LoginDs' });
        } else {
            // If logged in, proceed to the requested route
            next();
        }
    } else {
        // Proceed to routes that do not require authentication
        next();
    }
});

export default router;