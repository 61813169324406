<template>
<a href=# class=apbc-scroll-top v-show=showScrollTop aria-label="Scroll to top">
<i class="fas fa-angle-double-up"></i>
</a>
<HeaderCom />
<section class=saasio-breadcurmb-section-produk>
<div class=product-background></div>
<div class=container>
<div class=product-content-wrapper>
<div class=product-image-container>
<img :src="getImageUrl('banner_produk.webp')" :srcset=productImageSrcSet :sizes=imageSizes class=main-product-image alt="Product Showcase">
</div>
<div class=product-text-content>
<div class=breadcurmb-title-produk>
<h1>{{ $t('ProdukPages.title') }}</h1>
<p>{{ $t('ProdukPages.subtitle') }}</p>
</div>
</div>
<div class=accent-wave-wrapper>
<img :src="getImageUrl('banner_wave.webp')" :srcset=waveImageSrcSet :sizes=imageSizes class=accent-wave alt="Accent Wave">
</div>
</div>
</div>
</section>
<ProductSection />
<InnovationSection />
<ScrollToFooterButton />
<FooterCom />
</template>
<script>
import{defineAsyncComponent as e}from"vue";import{metaMixin as o}from"@/mixins/metaMixin";let HeaderCom=e(()=>import("@/components/HeaderCom.vue")),FooterCom=e(()=>import("@/components/FooterCom.vue")),ProductSection=e(()=>import("@/components/Product/ProductSection.vue")),InnovationSection=e(()=>import("@/components/Product/InnovationSection.vue")),ScrollToFooterButton=e(()=>import("@/components/ScrollToFooterButton.vue"));export default{name:"ProductPage",mixins:[o],components:{HeaderCom,ProductSection,InnovationSection,ScrollToFooterButton,FooterCom},data:()=>({showScrollTop:!1}),computed:{productImageSrcSet(){return`${this.getImageUrl("banner_produk_sm.webp")} 390w, 
              ${this.getImageUrl("banner_produk_mobile.webp")} 600w, 
              ${this.getImageUrl("banner_produk.webp")} 1200w`},waveImageSrcSet(){return`${this.getImageUrl("banner_wave_sm.webp")} 390w, 
              ${this.getImageUrl("banner_wave_mobile.webp")} 600w, 
              ${this.getImageUrl("banner_wave.webp")} 1200w`},imageSizes:()=>"(max-width: 390px) 390px, (max-width: 600px) 600px, 1200px"},mounted(){window.addEventListener("scroll",this.handleScroll)},beforeUnmount(){window.removeEventListener("scroll",this.handleScroll)},methods:{handleScroll(){this.showScrollTop=window.pageYOffset>200},getImageUrl:e=>`/assets/img/business-4/slider/${e}`}};
</script>
<style src="@/assets/css/view/produk.css"></style>