<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">Pura MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Blog Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanArtikel" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"></i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="store" class="card-body">
<div class="form-group">
<label for="title_en">Title EN:</label>
<ckeditor :editor="editor" v-model="post.judul_en" :config="editorConfig" @ready="onEditorReady" required></ckeditor>
</div>
<div class="form-group">
<label for="title_id">Title ID:</label>
<ckeditor :editor="editor" v-model="post.judul_id" :config="editorConfig" @ready="onEditorReady" required></ckeditor>
</div>
<div class="form-group">
<label for="id_kategori">Category:</label>
<select id="id_kategori" v-model="post.id_kategori" class="form-control" required>
<option value="">Select Category</option>
<option v-for="category in categories" :key="category.id_kategori" :value="category.id_kategori">
{{ category.nama_kategori }}
</option>
</select>
</div>
<div class="form-group">
<label for="tanggal">Date:</label>
<input type="date" id="tanggal" v-model="post.tanggal" class="form-control" required>
</div>
<div class="form-group">
<label for="articleContent">Deskripsi EN:</label>
<ckeditor :editor="editor" v-model="post.deskripsi_en" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label for="articleContent">Deskripsi ID:</label>
<ckeditor :editor="editor" v-model="post.deskripsi_id" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label>Content EN:</label>
<ckeditor :editor="editor" v-model="post.keterangan_en" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label>Content ID:</label>
<ckeditor :editor="editor" v-model="post.keterangan_id" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label>Content 2 EN:</label>
<ckeditor :editor="editor" v-model="post.keterangan_en2" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label>Content 2 ID:</label>
<ckeditor :editor="editor" v-model="post.keterangan_id2" :config="editorConfig" @ready="onEditorReady"></ckeditor>
</div>
<div class="form-group">
<label for="pembuat">Author:</label>
<input type="text" id="pembuat" v-model="post.pembuat" class="form-control" required>
</div>
<div class="form-group">
<label for="customTags">Keyword Tags:</label>
<select id="keyword" ref="keyword" multiple class="form-control">
<option v-for="tag in post.keyword" :value="tag" :key="tag">{{ tag }}</option>
</select>
</div>
<div v-for="n in 4" :key="'ref'+n" class="form-group">
<label :for="'link_ref' + n">Reference {{ n }}:</label>
<input :id="'link_ref' + n" v-model="post['link_ref' + n]" class="form-control" :placeholder="'Enter Reference ' + n">
</div>
<div v-for="n in 3" :key="'img'+n" class="form-group">
<label :for="'gambar' + n" class="mb-2">
Image for {{ ['Home', 'Product List', 'Product Detail'][n-1] }}:
<span class="text-muted">(Max 5MB, Format: JPG, PNG, GIF, WEBP)</span>
</label>
<div class="image-upload-container">
<input type="file" :id="'gambar' + n" class="form-control-file" @change="handleImageUpload($event, n)" accept="image/jpeg,image/png,image/gif,image/webp" :disabled="isSubmitting">
<div v-if="isSubmitting" class="upload-loading">
Uploading...
</div>
<div v-if="imagePreview[n-1] || post[`gambar${n}`]" class="preview-container mt-2">
<img :src="imagePreview[n-1] || getFileUrl(post[`gambar${n}`])" :alt="`Preview ${['Home', 'Product List', 'Product Detail'][n-1]}`" class="img-preview">
<button type="button" class="btn btn-sm btn-danger remove-image" @click="removeImage(n)" :disabled="isSubmitting">
<i class="fas fa-times"></i>
</button>
</div>
</div>
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary" :disabled="isSubmitting || isLoading">
{{ isSubmitting ? 'Submitting...' : 'Submit' }}
</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
</div>
</template>
<script>
import $ from 'jquery';
import 'select2';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

// Custom Upload Adapter with enhanced image handling
class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            this.loader.file
                .then(file => {
                    const formData = new FormData();
                    formData.append('gambar1', file);

                    axios.post(
                        'https://puracigarettesolutions.com:3000/api/artikel/CreateArtikel',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                    .then(response => {
                        if (response.data && response.data.data && response.data.data.gambar1) {
                            resolve({
                                default: `https://puracigarettesolutions.com:3000${response.data.data.gambar1}`,
                                // Different sizes for responsive images
                                '160': `https://puracigarettesolutions.com:3000${response.data.data.gambar1}`,
                                '500': `https://puracigarettesolutions.com:3000${response.data.data.gambar1}`,
                                '1000': `https://puracigarettesolutions.com:3000${response.data.data.gambar1}`,
                                '1052': `https://puracigarettesolutions.com:3000${response.data.data.gambar1}`
                            });
                        } else {
                            reject('Image upload failed: No URL returned');
                        }
                    })
                    .catch(error => {
                        console.error('Upload error:', error);
                        reject(error.response?.data?.message || 'Upload failed');
                    });
                })
                .catch(err => {
                    console.error('File loading error:', err);
                    reject(err);
                });
        });
    }

    abort() {
        // Implement abort logic if needed
    }
}

export default {
  name: "BlogEntryForm",
  components: { 
    FooterDs, 
    NavbarDs, 
    SidebarDs, 
    ckeditor: CKEditor.component 
  },

  data() {
    return {
      categories: [],
      isLoading: true,
      post: {
        judul_en: '',
        judul_id: '',
        tanggal: null,
        keterangan_en: '',
        keterangan_id: '',
        keterangan_en2: '',
        keterangan_id2: '',
        deskripsi_en: '',
        deskripsi_id: '',
        pembuat: '',
        link_ref1: '',
        link_ref2: '',
        link_ref3: '',
        link_ref4: '',
        keyword: [],
        id_kategori: '',
        gambar1: null,
        gambar2: null,
        gambar3: null
      },
      imagePreview: ['', '', ''],
      editor: ClassicEditor,
      editorConfig: {
  language: 'en',
  toolbar: {
    items: [
      'undo', 'redo',
      '|',
      'heading',
      '|',
      'bold', 'italic', 'strikethrough', 'underline',
      '|',
      'link', 'bulletedList', 'numberedList',
      '|',
      'indent', 'outdent',
      '|',
      'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed',
      '|',
      'alignment',
      '|',
      'removeFormat'
    ],
    shouldNotGroupWhenFull: true
  },
  image: {
    toolbar: [
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'toggleImageCaption',
      'imageTextAlternative'
    ],
    styles: {
      options: [
        'inline',
        'alignLeft',
        'alignRight',
        'alignCenter',
        'alignBlockLeft',
        'alignBlockRight',
        'block',
        'side'
      ]
    }
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow', 
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties',
      'tableWidth'
    ]
  },
  alignment: {
    options: ['left', 'center', 'right', 'justify']
  }
},
      isSubmitting: false
    };
  },

  async created() {
    try {
      await this.fetchCategories();
    } catch (error) {
      console.error('Error in created hook:', error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    this.initializeSelect2();
    this.initializeEditorStyles();
  },  // Add this comma and separate methods

  methods: {    // Start methods as a new section

    initializeEditorStyles() {
  const style = document.createElement('style');
  style.textContent = `
    /* Base styles for editor content */
    .ck-content {
      padding: 0 20px;
      max-width: 100%;
      margin: 0 auto;
    }

    /* Text content styles - remove spacing */
    .ck-content p {
      margin: 0 40px;  /* Remove vertical margins */
      line-height: 1.6;
    }

    /* Reset margins for headings */
    .ck-content h1, 
    .ck-content h2, 
    .ck-content h3,
    .ck-content h4, 
    .ck-content h5, 
    .ck-content h6 {
      margin: 0 40px;  /* Remove vertical margins */
      padding: 0;
    }

    /* Image styles */
    .ck-content .image {
      margin: 0 -20px;  /* Only horizontal negative margin */
      max-width: calc(100% + 40px);
      clear: both;
    }

    .ck-content .image img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
    }

    /* Image alignment styles */
    .ck-content .image.image-style-side {
      float: right;
      margin: 0 -20px 0 1.5em;
      max-width: 50%;
    }

    .ck-content .image.image-style-align-left {
      float: left;
      margin: 0 1.5em 0 -20px;
      max-width: 50%;
    }

    .ck-content .image.image-style-align-right {
      float: right;
      margin: 0 -20px 0 1.5em;
      max-width: 50%;
    }

    .ck-content .image.image-style-align-center {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }

    /* List styles - adjusted spacing */
    .ck-content ul,
    .ck-content ol {
      margin: 0 60px;
      padding-left: 40px;
    }

    /* Blockquote styles - adjusted spacing */
    .ck-content blockquote {
      margin: 0 60px;
      padding: 1em 40px;
      border-left: 5px solid #ccc;
      background: #f9f9f9;
    }

    /* Table styles - adjusted spacing */
    .ck-content table {
      margin: 0 40px;
      width: calc(100% - 80px);
      border-collapse: collapse;
    }

    .ck-content table td,
    .ck-content table th {
      border: 1px solid #bfbfbf;
      padding: 8px 12px;
    }

    /* Editor height */
    .ck-editor__editable {
      min-height: 400px;
    }

    /* Text formatting */
    .ck-content .text-tiny { font-size: 0.7em; }
    .ck-content .text-small { font-size: 0.85em; }
    .ck-content .text-big { font-size: 1.4em; }
    .ck-content .text-huge { font-size: 1.8em; }

    /* Figure captions - adjusted spacing */
    .ck-content figure.image figcaption {
      text-align: center;
      color: #666;
      font-size: 0.9em;
      margin: 0;
      padding-top: 0.5em;
    }

    /* Custom indent for specific content */
    .ck-content .indent {
      margin-left: 60px;
      margin-right: 60px;
      margin-top: 0;
      margin-bottom: 0;
    }

    /* Remove extra spacing between elements */
    .ck-content > :not(.image):not(table) + :not(.image):not(table) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    /* Ensure proper text wrapping */
    .ck-content {
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  `;
  document.head.appendChild(style);
},

// Dalam methods, ganti kedua method onEditorReady dengan yang ini:
onEditorReady(editor) {
  // Setup upload adapter
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };

  // Add custom handler for content formatting
  editor.conversion.for('downcast').add(dispatcher => {
    // Handle paragraphs
    dispatcher.on('insert:paragraph', (evt, data, conversionApi) => {
      const viewWriter = conversionApi.writer;
      const viewElement = conversionApi.mapper.toViewElement(data.item);
      
      if (viewElement) {
        viewWriter.addClass('content-paragraph', viewElement);
      }
    });

    // Handle images
    dispatcher.on('insert:image', (evt, data, conversionApi) => {
      const viewElement = conversionApi.mapper.toViewElement(data.item);
      if (viewElement) {
        conversionApi.writer.addClass('full-width-image', viewElement);
      }
    });
  });

  // Set default styles
  editor.editing.view.change(writer => {
    const root = editor.editing.view.document.getRoot();
    writer.setStyle({
      'width': '100%',
      'max-width': '100%',
      'margin': '0 auto'
    }, root);
  });
},

    async fetchCategories() {
      try {
        const response = await axios.get('http://192.168.98.220:3000/api/kategori/TampilanKategori');
        this.categories = response.data.data || [];
      } catch (error) {
        console.error('Error fetching categories:', error);
        alert('Failed to load categories. Please refresh the page.');
      }
    },

    initializeSelect2() {
  if (!this.$refs.keyword) return;

  const vm = this;
  $(this.$refs.keyword)
    .select2({
      tags: true,
      tokenSeparators: ['\n'], // Only use Enter key as separator
      theme: 'classic',
      placeholder: 'Type keyword and press Enter...',
      allowClear: true,
      multiple: true,
      createTag: function(params) {
        const term = $.trim(params.term);
        if (term === '') {
          return null;
        }

        // Check for duplicates case-insensitively
        const currentKeywords = vm.post.keyword || [];
        if (currentKeywords.some(k => k.toLowerCase() === term.toLowerCase())) {
          return null;
        }

        return {
          id: term,
          text: term,
          newTag: true
        };
      }
    })
    .on('select2:selecting', function(event) {
      const term = event.params.args.data.text;
      const currentKeywords = vm.post.keyword || [];
      
      // Prevent duplicate selection
      if (currentKeywords.some(k => k.toLowerCase() === term.toLowerCase())) {
        event.preventDefault();
      }
    })
    .on('change', function() {
      // Simply update the model with current selections
      const data = $(this).select2('data');
      vm.post.keyword = data.map(item => item.text);
    });

  // Handle Enter key press
  $(this.$refs.keyword).on('keydown', '.select2-search__field', function(event) {
    if (event.keyCode === 13) { // Enter key
      event.preventDefault();
      const inputValue = $(this).val().trim();
      
      if (inputValue) {
        const currentKeywords = vm.post.keyword || [];
        // Check for duplicates case-insensitively
        if (!currentKeywords.some(k => k.toLowerCase() === inputValue.toLowerCase())) {
          const newOption = new Option(inputValue, inputValue, true, true);
          $(vm.$refs.keyword).append(newOption);
        }
        // Clear input field
        $(this).val('');
      }
    }
  });

  // Initialize with existing keywords
  if (vm.post.keyword && vm.post.keyword.length > 0) {
    vm.post.keyword.forEach(keyword => {
      const newOption = new Option(keyword, keyword, true, true);
      $(vm.$refs.keyword).append(newOption);
    });
  }
},

removeImage(index) {
  // Clear file input
  const fileInput = document.querySelector(`#gambar${index}`);
  if (fileInput) fileInput.value = '';

  // Clear preview
  if (this.imagePreview[index - 1]) {
    URL.revokeObjectURL(this.imagePreview[index - 1]);
    this.imagePreview[index - 1] = '';
  }

  // Clear stored file
  this.imageFiles[index] = null;

  // Clear post data reference
  this.post[`gambar${index}`] = null;
},

async handleImageUpload(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        // File size validation (5MB)
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('File size must not exceed 5MB');
        }

        // File type validation
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        if (!allowedTypes.includes(file.type)) {
          throw new Error('File must be JPG, PNG, GIF, or WEBP');
        }

        // Clean up old preview if exists
        if (this.imagePreview[index - 1]) {
          URL.revokeObjectURL(this.imagePreview[index - 1]);
        }

        // Create new preview
        this.imagePreview[index - 1] = URL.createObjectURL(file);
        
        // Store file for upload
        if (!this.imageFiles) {
          this.imageFiles = {};
        }
        this.imageFiles[index] = file;

      } catch (error) {
        console.error('Error handling file:', error);
        await this.removeImage(index);
        alert(error.message || 'Failed to process image');
      }
    },

validateForm() {
  const requiredFields = [
    { field: 'judul_en', label: 'Title EN' },
    { field: 'judul_id', label: 'Title ID' },
    { field: 'tanggal', label: 'Date' },
    { field: 'pembuat', label: 'Author' },
    { field: 'id_kategori', label: 'Category' }
  ];

  const emptyFields = requiredFields.filter(({ field }) => !this.post[field]);
  
  if (emptyFields.length > 0) {
    alert(`Please fill in the following required fields: ${emptyFields.map(f => f.label).join(', ')}`);
    return false;
  }

  // Validate at least one image is selected
  const hasImage = Object.values(this.imageFiles).some(file => file !== null);
  if (!hasImage) {
    alert('Please select at least one image');
    return false;
  }

  return true;
},

    async store() {
  if (!this.validateForm()) return;
  if (this.isSubmitting) return;
  
  this.isSubmitting = true;
  
  try {
    const formData = new FormData();
    
    // Append text fields
    const textFields = [
      'judul_en',
      'judul_id',
      'id_kategori',
      'tanggal',
      'keterangan_en',
      'keterangan_id',
      'keterangan_en2',
      'keterangan_id2',
      'deskripsi_en',
      'deskripsi_id',
      'pembuat',
      'link_ref1',
      'link_ref2',
      'link_ref3',
      'link_ref4'
    ];

    textFields.forEach(field => {
      if (this.post[field] !== null && this.post[field] !== undefined) {
        formData.append(field, this.post[field]);
      }
    });

    // Append keywords
    if (Array.isArray(this.post.keyword) && this.post.keyword.length > 0) {
      formData.append('keyword', this.post.keyword.join(','));
    }

    // Append only the final image files
    for (let i = 1; i <= 3; i++) {
      const imageFile = this.imageFiles[i];
      if (imageFile) {
        formData.append(`gambar${i}`, imageFile);
      }
    }

    // Single API call to create article
    const response = await axios.post(
      'http://192.168.98.220:3000/api/artikel/CreateArtikel',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${percentCompleted}%`);
        }
      }
    );

    if (response.data?.success) {
      alert('Artikel berhasil disimpan');
      this.$router.push('/TampilanArtikel');
    } else {
      throw new Error(response.data?.message || 'Gagal menyimpan artikel');
    }
  } catch (error) {
    console.error('Store error:', error);
    alert(error.message || 'Gagal menyimpan artikel. Silakan coba lagi.');
  } finally {
    this.isSubmitting = false;
  }
},
  },

  beforeUnmount() {
    // Cleanup
    this.imagePreview.forEach(preview => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    });
    
    // Destroy Select2 instance
    if (this.$refs.keyword) {
      $(this.$refs.keyword).select2('destroy');
    }
  }
};

</script>


<style scoped>
.select2-container--classic .select2-selection--multiple,
.select2-container--classic .select2-selection--single {
  color: black;
}

:deep(.ck-editor__editable) {
  min-height: 300px;
}

.img-preview {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

/* Additional styles for the form */
.form-group {
  margin-bottom: 1.5rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

/* Select2 custom styling */
:deep(.select2-container--classic) {
  width: 100% !important;
}

:deep(.select2-container--classic .select2-selection--multiple) {
  min-height: 38px;
  border-radius: 4px;
}

:deep(.select2-container--classic .select2-selection--multiple .select2-selection__choice) {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 3px;
  margin: 3px;
}

:deep(.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove) {
  color: white;
  margin-right: 5px;
}

.editor-container {
  width: 100%;
  margin: 20px auto;
}

/* Custom styling for the editor */
.ck-editor__editable {
  min-height: 400px;
}

.ck.ck-editor {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: #fff;
  border: none;
}

.ck.ck-toolbar {
  border: none;
  border-bottom: 1px solid #ddd;
}
.image-upload-container {
  position: relative;
  margin-bottom: 1rem;
}

.img-preview {
  max-width: 200px;
  max-height: 200px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 3px;
}

.preview-container {
  position: relative;
  display: inline-block;
}

.remove-image {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}

.upload-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #666;
}

.text-muted {
  font-size: 0.8rem;
  font-weight: normal;
}

/* Disable input during submission */
input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
