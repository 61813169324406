<template>
<div id=quotes ref=quotesSection class=quotes-section :style=backgroundStyle>
<div class=quotes-content>
<h4><strong>{{ $t('QuotesPage.title') }}</strong></h4>
<p>{{ $t('QuotesPage.subtitle') }}</p>
</div>
</div>
</template>
<script>
import{ref as e,computed as s,onMounted as t,onUnmounted as a}from"vue";import{useI18n as u}from"vue-i18n";export default{name:"QuotesSection",setup(){let{t:i}=u(),r=e(null),n=e(!1),l=e(window.innerWidth),o=s(()=>l.value<=390?"sm":l.value<=600?"hp":"default"),b=s(()=>{let e=n.value?"":"_low",s=o.value,t;switch(s){case"sm":t=`/assets/img/business-4/back/quotes_sm${e}.webp`;break;case"hp":t=`/assets/img/business-4/back/quotes_hp${e}.webp`;break;default:t=`/assets/img/business-4/back/quotes${e}.webp`}return{backgroundImage:`url(${t})`,backgroundSize:"cover",backgroundPosition:"center",width:"100%",position:"relative"}}),c=()=>{let e=new Image;e.onload=()=>{n.value=!0},e.src=m()},m=()=>{let e=o.value;switch(e){case"sm":return"/assets/img/business-4/back/quotes_sm.webp";case"hp":return"/assets/img/business-4/back/quotes_hp.webp";default:return"/assets/img/business-4/back/quotes.webp"}},v,p=()=>{v&&clearTimeout(v),v=setTimeout(()=>{let e=o.value;l.value=window.innerWidth,e!==o.value&&(n.value=!1,c())},250)};return t(()=>{window.addEventListener("resize",p),c()}),a(()=>{window.removeEventListener("resize",p),v&&clearTimeout(v)}),{quotesSection:r,backgroundStyle:b,t:i}}};
</script>
<style src="@/assets/css/about/QuotesSection.css"></style>
